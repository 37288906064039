<template>
  <div ref="container" class="newsContainer">
    <div class="title">新闻设置</div>
    <div class="tableContainer">
      <el-button class="add" @click="addNews">新增新闻</el-button>
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
            @row-click="handleEdit"
          >
            <el-table-column label="ID" width="90" prop="sort" align="center" />

            <el-table-column
              label="新闻标题"
              min-width="270"
              prop="title"
              align="center"
            />

            <el-table-column
              label="发布时间"
              min-width="190"
              prop="createTime"
              align="center"
            />
            <el-table-column label="状态" min-width="140" align="center">
              <template slot-scope="scope">
                <span
                  :class="scope.row.status === 0 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleRun(scope.$index, scope.row)"
                  >启用</span
                >
                <span
                  :class="scope.row.status === 1 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleRun(scope.$index, scope.row)"
                  >停用</span
                >
              </template>
            </el-table-column>
            <el-table-column label="置顶" min-width="140" align="center">
              <template slot-scope="scope">
                <span
                  :class="scope.row.topFlag > 0 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleTop(scope.$index, scope.row)"
                  >置顶</span
                >
                <span
                  :class="scope.row.topFlag === 0 ? 'statusAc' : ''"
                  class="status"
                  @click.stop="handleTop(scope.$index, scope.row)"
                  >不置顶</span
                >
              </template>
            </el-table-column>
            <el-table-column label="管理操作" min-width="186" align="center">
              <template slot-scope="scope">
                <span class="editCommon" @click.stop="handleEdit(scope.row)"
                  >编辑</span
                >
                <span
                  class="deleteCommon"
                  @click.stop="handleDelete(scope.$index, scope.row)"
                  >删除</span
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- </div> -->
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="newsListDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/utils/eventBus";
import { getNewsList, deleteNews, setNewsTop } from "@/apis/user";
import { parseTime } from "@/utils/index";
export default {
  data() {
    return {
      tableHeight: "",
      newsListDetail: "",
      tableData: [],
      current: 1,
    };
  },
  // mounted() {
  //   this.getNewsList(this.current);
  // },
  activated() {
    EventBus.$on("needRefrush", () => {
      this.current = 1;
    });
    this.getNewsList(this.current);
  },
  methods: {
    getNewsList(current = 1) {
      getNewsList({ current }).then((res) => {
        var records = res.result.records;
        records.forEach((item) => {
          item.createTime = parseTime(item.createTime);
        });
        this.tableData = records;
        this.newsListDetail = res.result;
      });
    },
    handleRun(index, row) {
      if (row.status === 0) {
        row.status = 1;
      } else {
        row.status = 0;
      }
      deleteNews({
        newsId: row.newsId,
        status: row.status,
      }).then(() => {
        if (row.status === 0) {
          this.$message1("该条新闻已启用", "success");
        } else {
          this.$message1("该条新闻已停用", "success");
        }
      });
    },
    handleTop(index, row) {
      if (row.topFlag > 0) {
        row.topFlag = 0;
      } else {
        row.topFlag = 1;
      }
      const obj = {
        newsId: row.newsId,
        topFlag: row.topFlag,
      };
      setNewsTop(obj).then(() => {
        if (row.topFlag === 1) {
          // this.tableData.splice(index, 1)
          // this.tableData.unshift(row)
          this.$message1("该条新闻已置顶", "success");
        } else {
          this.$message1("该条新闻取消置顶", "success");
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        name: "NewsEdit",
        params: {
          newsId: row.newsId,
        },
      });
    },
    handleDelete(index, row) {
      this.$handleConfirm("请确认是否要删除这条新闻?")
        .then(() => {
          deleteNews({
            newsId: row.newsId,
            status: -1,
          }).then(() => {
            this.tableData.splice(index, 1);
            this.$message1("该条新闻已删除", "success");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addNews() {
      // console.log(11)
      this.$router.push({
        name: "NewsAdd",
      });
    },
    // 页码切换
    clickPage(e) {
      this.getNewsList(e);
      this.current = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.newsContainer {
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 30px;
  }
  .tableContainer {
    .add {
      float: none;
      margin: 12px 0 0 14px;
    }
  }
}
</style>
